import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Space,
  Table,
  Typography,
  Avatar,
  Spin,
  Image,
  Select,
  Input,
  Menu,
  Modal,
  Dropdown,
} from "antd";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import "./index.scss";
import { IconSalesforce, IconThreeDots } from "images";
import { useSelector } from "react-redux";
import { getSelectedWorkspaceProfileId } from "utils";
import { getDatasetsList } from "api/Datasets";
import ShareModal from "./ShareModal";

const { Option } = Select;

const MyDatasets = () => {
  const navigate = useNavigate();
  const userSession = useSelector((state) => state.authReducer.userSession);
  const profileWSId = getSelectedWorkspaceProfileId(userSession);
  const [dataListResponseData, setDataListResponseData] = useState([]);
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [page, setPage] = useState(1); // Start at page 1
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const [visibleModals, setVisibleModals] = useState({}); // Track modal visibility by row

  const avatarData = [
    { letter: "W", color: "#4285F4" },
    { letter: "A", color: "#DB4437" },
    { letter: "S", color: "#0F9D58" },
    { letter: "B", color: "#5C6BC0" },
    { letter: "S", color: "#8E44AD" },
  ];

  const fetchData = useCallback(
    async (page) => {
      if (profileWSId > 0) {
        try {
          const payload = { workspaceId: profileWSId.toString() };
          const res = await getDatasetsList(payload, page);
          setLoader(false);
          if (res.data?.data) {
            setDataListResponseData((prevData) => [
              ...prevData,
              ...res.data?.data,
            ]);
            if (res.data?.data.length === 0) {
              setHasMore(false);
            }
          } else {
            console.error("Fetched data is not an array", res.data?.data);
          }
        } catch (err) {
          setLoader(false);
          console.error("Error fetching data:", err);
        }
      }
    },
    [profileWSId]
  );

  useEffect(() => {
    if (!hasRunOnce && profileWSId > 0) {
      // Only run when profileWSId is valid
      fetchData(page);
      setHasRunOnce(true);
    }
  }, [profileWSId, fetchData, page]);

  // Handle modal visibility per row
  const handleMenuClick = (key, rowKey) => {
    console.log("key: ", key);
    if (key === "share") {
      setVisibleModals((prev) => ({ ...prev, [rowKey]: true }));
    }
  };

  const handleCancel = (rowKey) => {
    setVisibleModals((prev) => ({ ...prev, [rowKey]: false }));
  };

  const menu = (rowKey) => (
    <Menu onClick={({ key }) => handleMenuClick(key, rowKey)}>
      <Menu.Item key="share">Share</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: <span className="bold-title">NAME, CREATED, TAGS</span>,
      dataIndex: "name",
      render: (text, record) => (
        <div className="mydatasets__datasetInfo">
          <p className="mydatasets__p">{record.dataset_name}</p>
          <small className="mydatasets__small">
            Created By {record.user_name}
          </small>
        </div>
      ),
      className: "table-row",
    },
    {
      title: <span className="bold-title">STATUS</span>,
      dataIndex: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "Offline":
            color = "#cccccc";
            break;
          case "Active":
            color = "#84d830";
            break;
          case "Unavailable":
            color = "red";
            break;
          default:
            color = "default";
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Avatar
              size={10}
              shape="circle"
              style={{ backgroundColor: color, marginRight: "10px" }}
            />
            {status === "Active" ? "Live" : "Disabled"}
          </div>
        );
      },
      className: "table-row",
    },
    {
      title: <span className="bold-title">DESTINATION</span>,
      dataIndex: "destination",
      render: () => (
        <Space style={{ marginLeft: "25px" }}>
          <Image
            src={IconSalesforce}
            width={30}
            height={30}
            style={{ marginLeft: "4px" }}
            preview={false}
          />
        </Space>
      ),
      className: "table-row",
    },
    {
      title: "",
      dataIndex: "editcolumns",
      render: (text, record) => (
        <Space style={{ marginLeft: "25px" }}>
          <div className="avatars">
            <Avatar
              key={0}
              style={{ backgroundColor: avatarData[0].color }}
              className="avatars__circle"
            >
              {avatarData[0].letter}
            </Avatar>
            <div className="avatars__separator"></div>

            {avatarData.slice(1).map((item, index) => (
              <Avatar
                key={index}
                style={{ backgroundColor: item.color }}
                className="avatars__circle"
              >
                {item.letter}
              </Avatar>
            ))}
            <Avatar className="avatars__circle avatars__more">+5</Avatar>
          </div>

          <Dropdown
            className="avatars__ant-dropdown"
            overlay={menu(record.dataset_id)}
            trigger={["click"]}
          >
            <Image
              className="avatars__three-dots"
              src={IconThreeDots}
              preview={false}
            />
          </Dropdown>

          {/* Share Modal per row */}
          <ShareModal
            visible={visibleModals[record.dataset_id] || false}
            onCancel={() => handleCancel(record.dataset_id)}
          />
        </Space>
      ),
      className: "table-row",
    },
  ];

  // Handle search input change
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchInput(value);
  };

  return (
    <div className="mydatasets">
      <div className="mydatasets__headerDiv">
        <div className="mydatasets__header">
          {/* <p className="mydatasets__h1Description">
            These are the datasets that you have created
          </p> */}
        </div>
      </div>
      <div className="mydatasets__filter">
        <p> Andrew Hill is part of the following audiences</p>
      </div>
      <div className="datasetsListContainer__inputWrapper">
        <Input
          className="mydatasets__input"
          type="search"
          placeholder="Search for audiences by name"
          onChange={handleSearch}
          value={searchInput}
          prefix={<SearchOutlined style={{ color: "grey" }} />}
        />
      </div>
      {loader ? (
        <div className="loading-container">
          <Spin />
        </div>
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={dataListResponseData}
            pagination={true}
          />
          {!hasMore && <div style={{ textAlign: "center" }}>No more data</div>}
        </>
      )}
    </div>
  );
};

export default MyDatasets;
