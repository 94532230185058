import React, { useState } from "react";
import { Tabs, Input } from "antd";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import UnifiedProfile from "./UnifiedProfile";
import Predictions from "./Predictions";
import AITraits from "./AITraits";
import MyDatasets from "./MyDatasets";
import MySources from "./MySources";
import MyDestinations from "./MyDestinations";
import Identity from "./Identity";
import TableWithLists from "./UnifiedProfile/TableWithLists";

const Unified = () => {
  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("unified_profile");
  // const [activeTab, setActiveTab] = useState("audiences");

  const onTabClick = (key) => {
    setActiveTab(key);
  };
  return (
    <>
      <div className="tabs-container">
        <Tabs activeKey={activeTab} onChange={onTabClick}>
          <TabPane tab="Unified Profile" key="unified_profile">
            <UnifiedProfile />
          </TabPane>
          <TabPane tab="Identities" key="identities">
            <Identity />
          </TabPane>
          <TabPane tab="Sources" key="sources">
            <MySources />
          </TabPane>
          {/* <TabPane tab="Destinations" key="destinations">
            <MyDestinations />
          </TabPane> */}
          <TabPane tab="Tasks" key="tasks"></TabPane>
          {/*} <TabPane tab="Predictions" key="predictions">
            <Predictions />
  </TabPane>*/}
          <TabPane tab="AI Traits" key="ai_traits">
            <AITraits />
          </TabPane>
          <TabPane tab="Audiences" key="audiences">
            <MyDatasets />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default Unified;
