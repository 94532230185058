import React, { useState, useEffect } from "react";
import { Table, Input, Spin, Tooltip} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { searchPeople } from "api/search";
import "./index.scss";
import { ENTITY_TYPES } from "constants";
import { useSelector } from "react-redux";
import "./index.scss";

const Identity = () => {
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [loading, setLoading] = useState(false); // State for loader
  const [searchResults, setSearchResults] = useState([]); // State for API results
  const [filteredData, setFilteredData] = useState([]); // State for filtered data
  const { accountId, userId } = useSelector((state) => state.authReducer).userSession;
   // API call to fetch search results
   const fetchSearchResults = async (query) => {
    setLoading(true); // Show loader when API call starts
    const payload = {
      params: {
        query,
        userId,
        accountId,
        entityTypeId: ENTITY_TYPES.PEOPLE,
      },
    };

    try {
      const res = await searchPeople(payload);
      const results = res?.data?.data?.map((el) => ({
        user: `${el.first_name || ""} ${el.last_name || ""}`.trim(),
        universalId: el.people_id || "N/A",
        source: el.source,
        email: el.email,
        phone: el.phone_number,
        address: el.address,
        linkedin_url: el.linkedin_url,
        firstActivity: "10 days ago", // Example data, update if available
        lastActivity: "5 hrs ago", // Example data, update if available
        avatar: el.avatar || null,
      }));
      setSearchResults(results || []);
    } catch (err) {
      console.error("Error fetching search results:", err.message);
    } finally {
      setLoading(false); // Hide loader when API call completes
    }
  };

  // Use useEffect to fetch search results when searchTerm changes
  useEffect(() => {
    if (searchTerm) {
      fetchSearchResults(searchTerm);
    } else {
      fetchSearchResults(""); // Clear results if searchTerm is empty
    }
  }, [searchTerm]);

  // Filter data based on search term
  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = searchResults.filter((item) => {
      return (
        item?.user.toLowerCase().includes(lowerCaseSearchTerm)
      );
    });
    setFilteredData(filtered);
  }, [searchResults, searchTerm]);



  // Define table columns
  const columns = [
    {
      title: "PEOPLE ID",
      dataIndex: "universalId",
      key: "universalId",
      width: 100,
    },
    {
      title: "NAME",
      dataIndex: "user",
      key: "user",
      width: 200,
    },
    {
      title: "SOURCE",
      dataIndex: "source",
      key: "source",
      width: 100,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      width: 50,
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
      width: 200,
    },
    {
      title: "ADDRESS",
      dataIndex: "address",
      key: "address",
      width: 200,
      render: (text) => (
        <Tooltip title={text}>
        <span>{text?.length > 20 ? `${text.substring(0, 20)}...` : text}</span>
      </Tooltip>
      ),
      width: 150,
    },
    {
      title: "LINKEDIN URL",
      dataIndex: "linkedin_url",
      key: "linkedin_url",
      render: (text) => (
        <Tooltip title={ <a href={text} target="_blank" rel="noopener noreferrer">
        {text?.length > 200 ? `${text.substring(0, 200)}...` : text}
      </a>}>
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text?.length > 30 ? `${text.substring(0, 30)}...` : text}
        </a>
        </Tooltip>
      ),
      width: 200,

    },
    {
      title: "ACCURATE TO DATE",
      // dataIndex: "accurateToDate",
      // key: "accurateToDate",
    },
    
  ];

  return (
    <div className="identity-profile">
      <div className="identity-profile__inputWrapper">
        <Input
          className="identity-profile__input"
          type="search"
          placeholder="Search by Name"
          prefix={<SearchOutlined style={{ color: "grey" }} />}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
        />
      </div>
       {/* Show loading spinner while data is being fetched */}
       <Spin spinning={loading} size="medium">
      <Table
        className="identity-profile__table"
        columns={columns}
        dataSource={filteredData}
        pagination={true}
      />
      </Spin>
    </div>
  );
};

export default Identity;
