import React, { useState, useEffect } from "react";
import { Modal, Input, List, Avatar, Dropdown, Menu, Button } from "antd";
import { DownOutlined, LinkOutlined } from "@ant-design/icons";
import LetterAvatar from "react-letter-avatar";
import "./index.scss";

const ShareModal = ({ visible, onCancel }) => {
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  useEffect(() => {
    console.log("here is my modal"); // This will only run once when the component mounts
  }, []);

  const showShareMenu = (
    <Menu>
      <Menu.Item key="1">Shared</Menu.Item>
      <Menu.Item key="2">Not Shared</Menu.Item>
    </Menu>
  );

  const users = [
    {
      key: 1,
      name: "Kimball Bellmont (you)",
      email: "hamza@revcloud.com",
      status: "Owner",
      avatarUrl: "https://example.com/avatar1.jpg",
    },
    {
      key: 2,
      name: "Natasha James",
      email: "nj2019@gmail.com",
      status: "Shared",
      avatarUrl: "https://example.com/avatar2.jpg",
    },
    {
      key: 3,
      name: "Maria Norton",
      email: "nmariamariann@gmail.com",
      status: "Not Shared",
      avatarUrl: "https://example.com/avatar3.jpg",
    },
    {
      key: 4,
      name: "Andrew Jhones",
      email: "ajhonesa@gmail.com",
      status: "Shared",
      avatarUrl: "https://example.com/avatar4.jpg",
    },
    {
      key: 5,
      name: "Garfield Justin",
      email: "gjgjgj12@gmail.com",
      status: "Shared",
      avatarUrl: "https://example.com/avatar5.jpg",
    },
  ];

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="shareModal">
      <Modal
        title="Share 'LA_companies'"
        visible={visible}
        onCancel={onCancel}
        footer={
          <div className="share-modal__footer">
            <Button
              key="link"
              icon={<LinkOutlined style={{ marginTop: "4px" }} />}
              className="share-modal__link"
            >
              Copy invite link
            </Button>
            <Button
              onClick={onCancel}
              type="primary"
              className="share-modal__done"
            >
              Done
            </Button>
          </div>
        }
        className="share-modal"
      >
        <Input
          placeholder="Add people and groups"
          className="share-modal__input"
          onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm
        />

        <List
          itemLayout="horizontal"
          dataSource={filteredUsers}
          renderItem={(user) => (
            <List.Item className="share-modal__user">
              <List.Item.Meta
                avatar={<LetterAvatar name={user.name} size={25} radius={25} />}
                title={
                  <div className="share-modal__user-info">
                    <span className="share-modal__user-name">{user.name}</span>
                    <span className="share-modal__user-email">
                      {user.email}
                    </span>
                  </div>
                }
              />
              <Dropdown overlay={showShareMenu} trigger={["click"]}>
                <Button className="share-modal__status" type="link">
                  {user.status} <DownOutlined />
                </Button>
              </Dropdown>
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

export default ShareModal;
