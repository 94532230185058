import {
  Collapse,
  Image,
  Card,
  Spin,
  Input,
  Modal,
  List,
  Radio,
  Button,
  Select,
  Checkbox,
} from "antd";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { MarkDoneEmpty, MarkedGreen } from "images";
import { useDispatch, useSelector } from "react-redux";
import { setFormData, setStepCompletion } from "store/modules/datasets/action";
import { useState, useEffect } from "react";
import { Option } from "antd/lib/mentions";
import {
  getDestinationExtensions,
  createDestinationExtension,
} from "api/Datasets";

function SelectYourDestinations() {
  const { Panel } = Collapse;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.stepsReducer);
  const destinations = steps.destinations.newDestinations;

  console.log("steps: ", steps);
  console.log("steps.destinations: ", steps.destinations);
  const getSelectedSource =
    steps && steps.steps[7.1].data?.selectSource?.client_source_id; // value selected in step 7.1
  const [selectedSource, setSelectedSource] = useState(getSelectedSource);
  const [subDestinations, setSubDestinations] = useState({});
  const [tempSelectedSubDestinations, setTempSelectedSubDestinations] =
    useState({});
  const [selectedSubDestinations, setSelectedSubDestinations] = useState({});
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [selectedDestinationDetails, setSelectedDestinationDetails] = useState(
    {}
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newEntityName, setNewEntityName] = useState("");
  const [extensions, setExtensions] = useState([]);
  const [selectedExtension, setSelectedExtension] = useState(null);
  const [loadingExtensions, setLoadingExtensions] = useState(false);
  useEffect(() => {
    // Fetch extensions when the specific destination (ID 17) is selected
    if (selectedDestinationDetails?.source?.id == 1) {
      fetchExtensions();
    }
  }, [selectedDestinations]);

  const fetchExtensions = async () => {
    setLoadingExtensions(true); // Set loading state before API call
    try {
      const response = await getDestinationExtensions({
        connectionId: selectedDestinationDetails.client_source_id, // Assuming ID 17 is the first selected
        connectionName: selectedDestinationDetails.connection_name, // Replace with the actual name
      });
      setExtensions(response?.data?.data?.dataExtensions?.items || []);
    } catch (err) {
      // notification.error({
      //   message: "Error fetching extensions",
      //   description: err.message,
      // });
    } finally {
      setLoadingExtensions(false);
    }
  };

  const handleRadioChange = (id) => {
    // Update the selected destination to only have the currently selected ID
    setSelectedDestinations([id]); // Radio will only allow one selected, so use an array with a single element

    // Fetch and set details for the selected destination
    const selectedDetails = destinations.find(
      (dest) => dest.client_source_id === id
    );

    if (selectedDetails) {
      setSelectedDestinationDetails(selectedDetails); // Use object with single entry
      // handleDestinationValue(selectedDetails); // Send selected destination details
    } else {
      setSelectedDestinationDetails({});
    }
  };

  const handleSelectChange = (value) => {
    if (value === "__create__") {
      setIsModalVisible(true);
    } else {
      const selected = extensions.find((ext) => ext.id === value);
      if (selected) {
        console.log("Selected: " + selected);
        setSelectedExtension(selected);
      }
    }
  };
  const handleInputChange = (subStep, subStepKey, value, item) => {
    setSelectedSource(value);
    dispatch(setFormData(subStep, subStepKey, value, item));
    dispatch(setFormData(7.11, "extension", selectedExtension));
    dispatch(setStepCompletion(subStep, true, item));
    navigate(`/dashboard/datasets/datasets/save-dataset-details`);
  };
  // console.log(steps)
  const handleCheckboxChange = (id) => {
    const updatedSelection = selectedDestinations.includes(id)
      ? selectedDestinations.filter((item) => item !== id)
      : [...selectedDestinations, id];
    setSelectedDestinations(updatedSelection);
    // Find all user-configured destinations for the selected IDs
    const selectedDestinationsData = destinations?.filter((dest) =>
      updatedSelection.includes(dest.id)
    );
    // Extract all user-configured destinations for each selected destination
    const updatedSubDestinations = {};
    selectedDestinationsData.forEach((dest) => {
      updatedSubDestinations[dest.id] = dest.user_configured_destinations || [];
    });
    setSubDestinations(updatedSubDestinations);
    // Clear out unselected sub-destinations from tempSelectedSubDestinations
    const updatedTempSelectedSubDestinations = {};
    updatedSelection.forEach((destId) => {
      if (tempSelectedSubDestinations[destId]) {
        updatedTempSelectedSubDestinations[destId] =
          tempSelectedSubDestinations[destId];
      }
    });
    setTempSelectedSubDestinations(updatedTempSelectedSubDestinations);
  };

  const handleSearch = (input, option) => {
    return option.children[1].props.children
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const createExtension = async () => {
    try {
      const response = await createDestinationExtension({
        connectionId: selectedDestinationDetails.client_source_id, // Using client_source_id for connectionId
        connectionName: selectedDestinationDetails.connection_name, // Using connection_name from selectedDestinationDetails
        name: newEntityName, // New extension name
        categoryId: extensions[0].categoryId, // Assuming you want the categoryId of the selected extension
      });

      // Refresh extensions to include the newly created extension
      fetchExtensions();

      // Reset modal and input fields
      setNewEntityName("");
      setIsModalVisible(false);
    } catch (err) {}
  };

  const handleRedirect = () => {
    navigate("/dashboard/datasets/destination/catalogList");
  };

  const handleModalOk = () => {
    if (newEntityName) {
      createExtension();
    }
  };

  const handleModalCancel = () => {
    setNewEntityName("");
    setIsModalVisible(false);
  };
  // const handleSubDestinationChange = (destinationId, value) => {
  //   const valueArray = Array.isArray(value) ? value : [value];
  //   const subDestinationsMap = subDestinations[destinationId] || [];
  //   const selectedDetails = subDestinationsMap.filter((subDest) =>
  //     valueArray.includes(subDest.id)
  //   );
  //   setTempSelectedSubDestinations((prev) => {
  //     const updatedSelections = {
  //       ...prev,
  //       [destinationId]: selectedDetails,
  //     };

  //     const connectionDetails = Object.entries(updatedSelections).flatMap(
  //       ([key, items]) =>
  //         Array.isArray(items)
  //           ? items.map((item) => ({
  //               destinationKey: key,
  //               connectionId: item.id,
  //               connectionName: item.name,
  //             }))
  //           : []
  //     );

  //     let subDes = {
  //       connectionName: connectionDetails[0].connectionName,
  //       connectionId: connectionDetails[0].connectionId,
  //     };
  //     dispatch(setFormData("7.2", "subDestinations", subDes));

  //     setSelectedSubDestinations(updatedSelections);
  //     return updatedSelections;
  //   });
  // };

  const finalizeSelection = () => {
    console.log("selectedDestinationDetails: ", selectedDestinationDetails);

    let selectedDes = {
      connectionName: selectedDestinationDetails.connection_name,
      connectionId: selectedDestinationDetails.client_source_id,
    };
    dispatch(setFormData(7.1, "selectSource", selectedDes));

    console.log("selectedExtension: ", selectedExtension);
    let extension = {
      id: selectedExtension.id,
      name: selectedExtension.name,
      key: selectedExtension.key,
    };

    dispatch(setFormData(7.11, "extension", extension));

    dispatch(setStepCompletion(7.1, true));
    navigate(`/dashboard/datasets/datasets/save-dataset-details`);
  };

  // Determine if any destination or sub-destination is selected
  const isSelectionMade =
    selectedDestinations.length > 0 &&
    Object.values(tempSelectedSubDestinations).some(
      (subDest) => subDest.length > 0
    );

  const handleDisabledClick = () => {
    console.log("selectedDestinations: ", selectedDestinations);
    console.log("tempSelectedSubDestinations: ", tempSelectedSubDestinations);
    console.log("I am here ");
  };

  const CustomPanelHeader = ({ mainHeading, subHeading, number }) => (
    <Card className="selectYourDestination__customPanelHeader">
      <div className="selectYourDestination__customPanelHeader-header">
        <Image
          src={
            steps && steps.steps[number]?.completed
              ? MarkedGreen
              : MarkDoneEmpty
          }
          preview={false}
          className="selectYourDestination__customPanelHeader-image"
        />
        <div className="selectYourDestination__customPanelHeader-headingContainer">
          <div className="selectYourDestination__customPanelHeader-mainHeading">
            {mainHeading}
          </div>

          <div className="selectYourDestination__customPanelHeader-subHeading">
            {subHeading}
          </div>
        </div>
      </div>
    </Card>
  );

  const CustomList = () => (
    <List
      dataSource={destinations}
      locale={{
        emptyText: (
          <>
            <div>
              No destinations found. Only configured destinations will be
              displayed here.
            </div>
            <div>
              <Button
                type="primary"
                onClick={handleRedirect}
                className={"redirectBtn"}
              >
                Add Destination
              </Button>
            </div>
          </>
        ),
      }}
      renderItem={(item) => (
        <List.Item style={{ padding: 0, flexDirection: "column" }}>
          <Card
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              marginTop: "12px",
              borderRadius: "16px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              minHeight: "40px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Radio
                className="custom-radio"
                checked={selectedDestinations.includes(item.client_source_id)}
                onChange={() => handleRadioChange(item.client_source_id)}
              />
              <Image
                src={item.source.icon_url}
                width={30}
                height={30}
                style={{ marginLeft: "16px" }}
                preview={false}
              />
              <div
                style={{
                  marginLeft: "28px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: item.source.name ? "flex-start" : "center",
                  height: "100%",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontFamily: "Lato, sans-serif",
                    fontWeight: "501",
                    fontSize: "10.6pt",
                    color: "#000000",
                  }}
                >
                  {item.name}
                </p>
                {item.source.name && (
                  <p
                    style={{
                      margin: 0,
                      fontFamily: "Lato, sans-serif",
                      fontWeight: "300",
                      fontSize: "9.8pt",
                      color: "#000000",
                    }}
                  >
                    {item.source.name}
                  </p>
                )}
              </div>
            </div>
          </Card>

          {item.source.id === 1 &&
            selectedDestinationDetails?.source?.id == 1 &&
            item.client_source_id ===
              selectedDestinationDetails.client_source_id && (
              <>
                <Select
                  showSearch={true}
                  filterOption={handleSearch}
                  placeholder="Select an existing extension or create a new one"
                  className="custom-select"
                  style={{
                    width: "100%",
                    marginTop: "12px",
                  }}
                  dropdownStyle={{ maxHeight: 200, overflowY: "auto" }}
                  onChange={handleSelectChange}
                  value={selectedExtension?.name || undefined}
                  dropdownRender={(menu) => (
                    <>
                      <div
                        className="create-new-extension"
                        onClick={() => setIsModalVisible(true)}
                      >
                        + Create New Extension
                      </div>
                      {menu}
                    </>
                  )}
                >
                  {loadingExtensions ? (
                    <Option key="loading" disabled>
                      <Spin
                        size="small"
                        style={{ display: "flex", justifyContent: "center" }}
                      />
                    </Option>
                  ) : (
                    extensions.map((ext) => (
                      <Option key={ext.id} value={ext.id}>
                        {ext.name}
                      </Option>
                    ))
                  )}
                </Select>

                <Modal
                  title="Create New Extension"
                  visible={isModalVisible}
                  onOk={handleModalOk}
                  onCancel={handleModalCancel}
                  okText="Create"
                  className="custom-modal"
                >
                  <Input
                    value={newEntityName}
                    onChange={(e) => setNewEntityName(e.target.value)}
                    placeholder="Enter the name of the extension"
                    className="custom-input"
                  />
                </Modal>
              </>
            )}
        </List.Item>
      )}
    />
  );

  return (
    <div className="selectYourDestination">
      <Collapse defaultActiveKey={["1"]} collapsible="disabled">
        <Panel
          header={
            <CustomPanelHeader
              mainHeading="Select your destination"
              subHeading="No source selected"
            />
          }
          key="1"
        >
          <CustomList />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "12px",
              width: "100%",
            }}
          >
            <Button
              type="primary"
              className={isSelectionMade ? "saveButton" : "disabled"}
              // onClick={(item) =>
              //   handleInputChange(7.1, "selectSource", item.event_entity_type)
              // }
              onClick={() => {
                // if (!isSelectionMade) {
                //   handleDisabledClick();
                // } else {
                finalizeSelection();
                // }
              }}
            >
              Confirm
            </Button>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}

export default SelectYourDestinations;
