import React, { useState, useEffect } from "react";
import { Table, Avatar, Input, Spin } from "antd";
import { searchPeople } from "api/search";
import "./index.scss";
import { SearchOutlined } from "@ant-design/icons";
import LetterAvatar from "react-letter-avatar";
import { useNavigate } from "react-router-dom";
import { ENTITY_TYPES } from "constants";
import { useSelector } from "react-redux";

const { Search } = Input;

const columns = [
  {
    title: "USER",
    dataIndex: "user",
    key: "user",
    render: (text, record) => (
      <div className="profile-explorer__user">
        <LetterAvatar name={record.user} size={25} radius={25} />
        <span style={{ marginLeft: "6px" }}>{text}</span>
      </div>
    ),
  },
  {
    title: "UNIVERSAL ID",
    dataIndex: "universalId",
    key: "universalId",
  },
  {
    title: "FIRST ACTIVITY",
    dataIndex: "firstActivity",
    key: "firstActivity",
  },
  {
    title: "LAST ACTIVITY",
    dataIndex: "lastActivity",
    key: "lastActivity",
  },
];

const ProfileSearch = () => {
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [searchResults, setSearchResults] = useState([]); // State for API results
  const [filteredData, setFilteredData] = useState([]); // State for filtered data
  const [loading, setLoading] = useState(false); // State for loader
  const navigate = useNavigate();
  const { accountId, userId } = useSelector((state) => state.authReducer).userSession;

  // API call to fetch search results
  const fetchSearchResults = async (query) => {
    setLoading(true); // Show loader when API call starts
    const payload = {
      params: {
        query,
        userId,
        accountId,
        entityTypeId: ENTITY_TYPES.PEOPLE,
      },
    };

    try {
      const res = await searchPeople(payload);
      const results = res?.data?.data?.map((el) => ({
        user: `${el.first_name || ""} ${el.last_name || ""}`.trim(),
        universalId: el.people_id || "N/A",
        firstActivity: "10 days ago", // Example data, update if available
        lastActivity: "5 hrs ago", // Example data, update if available
        avatar: el.avatar || null,
      }));
      setSearchResults(results || []);
    } catch (err) {
      console.error("Error fetching search results:", err.message);
    } finally {
      setLoading(false); // Hide loader when API call completes
    }
  };

  // Use useEffect to fetch search results when searchTerm changes
  useEffect(() => {
    if (searchTerm) {
      fetchSearchResults(searchTerm);
    } else {
      fetchSearchResults(""); // Clear results if searchTerm is empty
    }
  }, [searchTerm]);

  // Filter data based on search term
  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = searchResults.filter((item) => {
      return item.user.toLowerCase().includes(lowerCaseSearchTerm);
    });
    setFilteredData(filtered);
  }, [searchResults, searchTerm]);

  const handleRowClick = (record) => {
    const personName = record?.user;
    navigate(`/dashboard/datasets/explorer/${personName}`);
  };

  return (
    <div className="profile-explorer">
      <div className="profile-explorer__header">
        <h2></h2>
        <Input
          className="profile-explorer__header__input"
          type="search"
          placeholder="Search by User Name"
          prefix={<SearchOutlined style={{ color: "grey" }} />}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
        />
      </div>
      {/* Show loading spinner while data is being fetched */}
      <Spin spinning={loading} size="medium">
        <Table
          columns={columns}
          dataSource={filteredData} // Use the dynamic search results from the API
          pagination={true}
          className="profile-explorer__content"
          onRow={(record) => {
            return {
              onClick: () => {
                handleRowClick(record);
              },
              onMouseEnter: (e) => {
                e.currentTarget.style.cursor = "pointer";
              },
              onMouseLeave: (e) => {
                e.currentTarget.style.cursor = "default";
              },
            };
          }}
        />
      </Spin>
    </div>
  );
};

export default ProfileSearch;
