import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Space,
  Table,
  Tag,
  Typography,
  Avatar,
  Spin,
  Image,
  Select,
  Input
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { IconSalesforce, IconThreeDots } from "images";

const { Option } = Select;

const DatasetsDesktop = ({ datasetsListData, loader, handlePageNumber, hasMore }) => {
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchInput, setSearchInput] = useState("");

  const columns = [
    {
      title: <span className="bold-title">NAME, CREATED, TAGS</span>,
      dataIndex: "name",
      render: (text, record) => (
        <div className="datasetsListContainer__datasetInfo">
          <p className="datasetsListContainer__p">{record.dataset_name}</p>
          <small className="datasetsListContainer__small">
            Created By {record.user_name}
          </small>
        </div>
      ),
      className: "table-row",
    },
    {
      title: <span className="bold-title">STATUS</span>,
      dataIndex: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "Offline":
            color = "#cccccc";
            break;
          case "Active":
            color = "#84d830";
            break;
          case "Unavailable":
            color = "red";
            break;
          default:
            color = "default";
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Avatar
              size={10}
              shape="circle"
              style={{ backgroundColor: color, marginRight: "10px" }}
            />
            {status == "Active" ? "Live" : "Disabled"}
          </div>
        );
      },
      className: "table-row",
    },
    {
      title: <span className="bold-title">DESTINATION</span>,
      dataIndex: "destination",
      render: () => (
        <Space style={{ marginLeft: "25px" }}>
          <Image
            src={IconSalesforce}
            width={30}
            height={30}
            style={{ marginLeft: "4px" }}
            preview={false}
          />
        </Space>
      ),
      className: "table-row",
    },
    {
      title: "",
      dataIndex: "editcolumns",
      render: () => (
        <Space style={{ marginLeft: "25px" }}>
          <Image src={IconThreeDots} preview={false} />
        </Space>
      ),
      className: "table-row",
    },
  ];

  const handleSearch = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const filterByStatus = (data) => {
    console.log("data", data)
    switch (statusFilter) {
      case 'all':
        return data; // Show all datasets
      case 'enabled':
        return data.filter((item) => item.status == "Active"); // Show only enabled datasets
      case 'disabled':
        return data.filter((item) => item.status == false); // Show only disabled datasets
      default:
        return data;
    }
  };

  const handleStatusChange = (value) => {
    setStatusFilter(value);
  };

  const filteredData = filterByStatus(datasetsListData).filter((item) =>
    item.dataset_name.toLowerCase().includes(searchInput)
  );

  return (
    <div className="datasetsListContainer">
      <div className="datasetsListContainer__headerDiv">
        <div className="datasetsListContainer__header">
          <Typography.Text className="datasetsListContainer__h1Text">
            List of Audiences
          </Typography.Text>
          <p className="datasetsListContainer__h1Description">
            These are the audiences that you have created
          </p>
        </div>
        <Input
          className="datasetsListContainer__input"
          type="search"
          placeholder="Search for audiences by name"
          onChange={handleSearch}
          value={searchInput}
          prefix={<SearchOutlined style={{ color: "grey" }} />}
        />
        <Button
          className="datasetsListContainer__newDatasetBtn"
          onClick={() => navigate(`/dashboard/datasets/datasets/steps`)}
          icon={<PlusOutlined />}
        >
          New audience
        </Button>
      </div>
      <div className="datasetsListContainer__filter">
        <Select
          className="datasetsListContainer__status-filter"
          defaultValue="all"
          onChange={handleStatusChange}
          style={{ width: 165 }} // Adjust width as needed
        >
          <Option value="all">
            <span className="Status all-icon"></span> All
          </Option>
          <Option value="enabled">
            <span className="Status enabled-icon"></span> Live
          </Option>
          <Option value="disabled">
            <span className="Status disabled-icon"></span> Disabled
          </Option>
        </Select>
      </div>
      {loader ? (
        <div className="loading-container">
          <Spin />
        </div>
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={filteredData} // Apply the filtered data here
            pagination={true}
          />
          {!hasMore && <div style={{ textAlign: 'center' }}>No more data</div>}
        </>
      )}
    </div>
  );
};

export default DatasetsDesktop;
