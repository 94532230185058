import React, { useEffect, useState } from "react";
import { Alert, Table, Tag, Tabs, Spin, Button, Input, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import Flex from "screens/Chat/Flex";
import { useSelector, useDispatch } from "react-redux";
import { getConnectorOverviewList } from "store/modules/connector/action";
import "./index.scss";
import CsvOverview from "../CsvOverview";
import { Dropdown } from "antd";
import {
  SearchOutlined,
  UserOutlined,
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { getSelectedWorkspaceProfileId } from "utils";
import { createCsvMapping, getFiles } from "api/FileOnboard";
import { FON_STAGE } from "../Onboarding/Uploads/UnprocessedFiles";
import { setCurrentProcessingGPTDetails } from "store/modules/fileOnboard/action";
import FileUpload from "components/FileUpload";
import InprogressUploads from "components/InprogressUploads";

function ConnectorOverview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const connectorOverviewList = useSelector(
    (state) => state?.connectorReducer?.connectorOverviewList
  );
  const loading = useSelector((state) => state?.connectorReducer?.loading);
  const { sourceId, sourceName, connectionName, connectorId, active } =
    useParams();

  // const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [reFetchFileDetails, setReFetchFileDetails] = useState(true);
  const newFileUploaded = useSelector(
    (state) => state.fileOnboardReducer
  ).newFileUploaded;
  const userSession = useSelector((state) => state.authReducer).userSession;

  const navigateToModel = () => {
    navigate(
      `/dashboard/datasets/model/creation/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
    );
  };

  // useEffect(() => {
  // }, [connectorId]);

  useEffect(() => {
    const payload = {
      user_id: userSession.userId,
      profileworkspaceId: getSelectedWorkspaceProfileId(userSession),
    };
    if (newFileUploaded) {
      setLoadingText("Validating file(s), please wait...");
    }
    // setLoading(true);
    const isFileUploadedRecently =
      new Date().getTime() - newFileUploaded < 3000; //Within 3 sec
    setTimeout(
      () => {
        getFiles(payload)
          .then((res) => {
            console.log("Files A", res);
            const files = res.data.data.response || [];
            const readyForStep2Files = [];

            files.forEach((file) => {
              switch (file.status) {
                case FON_STAGE.UNPROCESSED:
                  readyForStep2Files.push(file);
                  break;
                default:
                  break;
              }
            });

            const continueButton = {
              file_id: 0,
              filename: "",
              status: FON_STAGE.CONTINUE_BUTTON,
              stage: "",
              s3_url: "",
              error: null,
              reason: null,
              created: "2024-02-01T09:32:44.278Z",
              last_modified: "2024-02-01T09:32:44.278Z",
            };

            if (readyForStep2Files.length > 0) {
              readyForStep2Files.push(continueButton);
            }
            // setReadyForStep2List(readyForStep2Files);
            if (readyForStep2Files.length > 0 && newFileUploaded) {
              setLoadingText("Validating file(s) schema, please wait...");
              console.log("Files B", readyForStep2Files);
              // setLoading(true);

              let fileIds = readyForStep2Files
                .filter((fileInfo) => fileInfo.file_id !== 0)
                .map((fileInfo) => fileInfo.file_id);

              const payload = { files: fileIds };

              createCsvMapping(payload)
                .then((res) => {
                  console.log("Files C", res);
                  if (res.data.error) {
                    // message.error(res.data.message);
                  } else {
                    message.success(res.data.message);
                    if (res.data.message) {
                      dispatch(setCurrentProcessingGPTDetails(res?.data?.data));
                      setReFetchFileDetails(!reFetchFileDetails);
                      // navigate(`/dashboard/datasets/sources`);
                      // onClickAddSource();
                    }
                  }
                })
                .catch((err) => {
                  message.error(err.data.message);
                })
                .finally(() => {
                  // setLoading(false);
                });
            }
          })
          .catch((err) => {
            message.error(err.message);
            // setLoading(false);
          })
          .finally(() => {
            // setLoading(false);
          });
      },
      isFileUploadedRecently ? 3000 : 0 //Give time for recent file to be available in db before fetching the list
    );
  }, [newFileUploaded, reFetchFileDetails]);

  const tableContainerStyle = {
    height: "calc(100vh - 230px)",
    overflow: "auto",
  };

  const handleRowClick = () => {
    if (active == "false") {
      navigate(
        `/dashboard/datasets/settings/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
  };

  const warningMessage = (
    <div>
      {connectionName} is disabled.
      <br />
      <a
        href="#"
        onClick={handleRowClick}
        style={{
          color: "#1790ff",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        Enable {connectionName}
      </a>{" "}
      to start receiving data again.
    </div>
  );

  const columns = [
    {
      title: "STATUS",
      dataIndex: "last_execution_status",
      key: "status",
      render: (text) => {
        const customTagStyle = {
          borderRadius: "10px", // Adjust the border-radius to make it round
          padding: "0px 5px 0px 8px", // Adjust padding for size
          fontSize: "12px", // Adjust font size
          // width: "80px",
        };

        const tagColor = text === "Successful" ? "green" : "red";

        return (
          <>
            {connectorId == 6 ? (
              <Tag color={tagColor} style={customTagStyle}>
                <strong>
                  {text === "Successful" ? "Data Fetched" : "Failed"}
                </strong>
              </Tag>
            ) : (
              <Tag color={tagColor} style={customTagStyle}>
                <strong>
                  {text === "Successful" ? "Data Flowing" : "Failed"}
                </strong>
              </Tag>
            )}
          </>
        );
      },
    },

    {
      title: "NAME",
      dataIndex: "event_type",
      key: "name",
    },
    {
      title: "STARTED",
      dataIndex: "last_execution_time",
      key: "started",
      render: (text, record) => {
        let lastExecutionDate = record.last_execution_time
          ? new Date(record.last_execution_time)
          : new Date(record.last_modified);
        // Calculate the time difference in milliseconds
        const timeDifference = Date.now() - lastExecutionDate.getTime();

        // Convert the time difference to hours
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

        // Format the last_execution_time
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(lastExecutionDate);

        let displayText;

        if (hoursDifference < 24) {
          // Display in hours
          displayText = `${hoursDifference} hours ago`;
        } else {
          // Convert to days
          const daysDifference = Math.floor(hoursDifference / 24);
          displayText = `${daysDifference} days ago`;
        }

        return (
          <div>
            {displayText}
            <br />
            <span style={{ fontSize: "12px", color: "#888" }}>
              {formattedDate}
            </span>
          </div>
        );
      },
    },

    {
      title: "DURATION",
      dataIndex: "total_execution_time",
      key: "duration",
      render: (text) => {
        if (text !== null) {
          const durationInSeconds = parseFloat(text);

          if (durationInSeconds < 60) {
            // Display in seconds
            return <span>{Math.round(durationInSeconds)}s</span>;
          } else if (durationInSeconds < 3600) {
            // Convert to minutes
            const durationInMinutes = durationInSeconds / 60;
            return <span>{Math.round(durationInMinutes)}min</span>;
          } else if (durationInSeconds < 86400) {
            // Convert to hours
            const durationInHours = durationInSeconds / 3600;
            return <span>{Math.round(durationInHours)}h</span>;
          } else {
            // Convert to days
            const durationInDays = durationInSeconds / 86400;
            return <span>{Math.round(durationInDays)}d</span>;
          }
        } else {
          // If null, display 0ms
          return <span>{0}ms</span>;
        }
      },
    },
    {
      title: "SYNCED",
      dataIndex: "last_execution_records",
      key: "synced",
    },
  ];
  const handleModelClick = (record) => {
    console.log("clicked", record);
    if (connectorId == 10) {
      // Redirect to your new component
      const modelId = record?.id;
      const modelName = record?.model_name;
      navigate(`/dashboard/datasets/model/overview/${modelId}/${modelName}`);
    }
  };
  const postgresColumns = [
    {
      title: "NAME",
      dataIndex: "model_name",
      key: "name",
    },

    {
      title: "MODELLING METHOD",
      dataIndex: "modelling_method",
      key: "modelling",
    },
    {
      title: "DESTINATIONS",
      dataIndex: "destination",
      key: "destinations",
    },
  ];

  const items = [
    connectorId == 10
      ? { key: "1", label: "Model" }
      : { key: "1", label: "Overview" },
    connectorId == 8 ? { key: "2", label: "Mapping" } : null,
    connectorId == 8 ? { key: "3", label: "Tasks" } : null,
    { key: "4", label: "Settings" },
  ];

  const onChange = (activeKey) => {
    if (activeKey === "2") {
      navigate(
        `/dashboard/datasets/sources/mapping/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
    if (activeKey === "3") {
      navigate(
        `/dashboard/datasets/sources/tasks/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
    if (activeKey === "4") {
      navigate(
        `/dashboard/datasets/settings/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
  };

  useEffect(() => {
    dispatch(getConnectorOverviewList(sourceId));
  }, [dispatch, sourceId]);

  const Toolbar = () => {
    const [isSearching, setIsSearching] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const handleSearchClick = () => {
      setIsSearching(true);
    };

    const handleInputChange = (e) => {
      const value = e.target.value;
      setSearchValue(value);
      if (value === "") {
        setIsSearching(false);
      }
    };

    const onAddFiles = () => {
      console.log("Add file clicked");
    };

    return (
      <div className="toolbar">
        {isSearching ? (
          <Input
            className="toolbar__input"
            placeholder="Search with file name"
            value={searchValue}
            onChange={handleInputChange}
            style={{ width: "200px" }}
          />
        ) : (
          <Button
            className="toolbar__button toolbar__button--search"
            shape="circle"
            icon={<SearchOutlined />}
            onClick={handleSearchClick}
          />
        )}
        <Button className="toolbar__button toolbar__button--share">
          <UserOutlined />
          <span className="toolbar__text">Share</span>
          <DownOutlined />
        </Button>
        <FileUpload>
          <Button
            className="toolbar__button toolbar__button--add-files"
            onClick={onAddFiles}
          >
            <PlusOutlined />
            <span className="toolbar__text">ADD FILES</span>
          </Button>
        </FileUpload>
        <div className="toolbar__separator"></div>
      </div>
    );
  };

  return (
    <div className="ConnectorOverview">
      <div className="ConnectorOverview__tabsWrapper">
        <div className="ConnectorOverview__tabsContainer">
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          {connectorId === "8" ? <Toolbar /> : null}
        </div>
      </div>
      {loading ? (
        <div className="loading-spinner">
          <Spin />
        </div>
      ) : connectorId === "8" ? (
        <div>
          <CsvOverview />
          <InprogressUploads />
        </div>
      ) : (
        <div className="ConnectorOverview__content">
          <div
            className="ConnectorOverview__tableDiv"
            style={tableContainerStyle}
          >
            {active == "false" && (
              <Alert
                message={warningMessage}
                type="warning"
                showIcon
                style={{ marginBottom: "16px" }}
              />
            )}
            {connectorId == 10 ? (
              <div className="ConnectorOverview__parentButton">
                <Button
                  onClick={() => navigateToModel()}
                  className="ConnectorOverview__button"
                >
                  Add Model
                </Button>
              </div>
            ) : null}
            <Table
              columns={connectorId == 10 ? postgresColumns : columns}
              dataSource={connectorOverviewList?.reverse()}
              pagination={true}
              onRow={(record) => ({
                onClick: () => handleModelClick(record),
              })}
              rowClassName="hover-pointer"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ConnectorOverview;
