import { Table, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./index.scss";

const AITraits = () => {
  const uploadData = [
    {
      key: "1",
      expectedRevenue: "$1,265.89",
      retargetingScore: "67",
      smsScore: "34",
      emailScore: "78",
      directScore: "98",
      callScore: "62",
    },
  ];

  const aiTraitsColumnsConfig = [
    {
      title: "EXPECTED REVENUE",
      dataIndex: "expectedRevenue",
      key: "expectedRevenue",
      width: 200,
      render: (text) => <div>{text}</div>,
    },
    {
      title: "RETARGETING SCORE",
      dataIndex: "retargetingScore",
      key: "retargetingScore",
      width: 180,
      render: (text) => (
        <div>
          <Tooltip title="This will show how this score was calculated">
            <ExclamationCircleOutlined
              style={{ marginRight: 8, marginBottom: 10, cursor: "pointer" }}
            />
          </Tooltip>
          {text} (0-100)
        </div>
      ),
    },
    {
      title: "SMS SCORE",
      dataIndex: "smsScore",
      key: "smsScore",
      width: 120,
      render: (text) => (
        <div>
          <Tooltip title="This will show how this score was calculated">
            <ExclamationCircleOutlined
              style={{ marginRight: 8, marginBottom: 10, cursor: "pointer" }}
            />
          </Tooltip>
          {text} (0-100)
        </div>
      ),
    },
    {
      title: "EMAIL SCORE",
      dataIndex: "emailScore",
      key: "emailScore",
      width: 120,
      render: (text) => (
        <div>
          <Tooltip title="This will show how this score was calculated">
            <ExclamationCircleOutlined
              style={{ marginRight: 8, marginBottom: 10, cursor: "pointer" }}
            />
          </Tooltip>
          {text} (0-100)
        </div>
      ),
    },
    {
      title: "DIRECT SCORE",
      dataIndex: "directScore",
      key: "directScore",
      width: 120,
      render: (text) => (
        <div>
          <Tooltip title="This will show how this score was calculated">
            <ExclamationCircleOutlined
              style={{ marginRight: 8, marginBottom: 10, cursor: "pointer" }}
            />
          </Tooltip>
          {text} (0-100)
        </div>
      ),
    },
    {
      title: "CALL SCORE",
      dataIndex: "callScore",
      key: "callScore",
      width: 120,
      render: (text) => (
        <div>
          <Tooltip title="This will show how this score was calculated">
            <ExclamationCircleOutlined
              style={{ marginRight: 8, marginBottom: 10, cursor: "pointer" }}
            />
          </Tooltip>
          {text} (0-100)
        </div>
      ),
    },
  ];

  const createUploadColumns = (config) => {
    const columns = config.map((columnConfig) => ({
      title: columnConfig.title,
      dataIndex: columnConfig.dataIndex,
      key: columnConfig.key,
      render:
        columnConfig.render ||
        ((text) => (
          <div className="unified-profile__icon-row">
            <span>{text}</span>
          </div>
        )),
    }));
    return columns;
  };

  return (
    <div className="unified-profile">
      <Table
        className="unified-profile__table"
        columns={createUploadColumns(aiTraitsColumnsConfig)}
        dataSource={uploadData}
        pagination={false}
        tableLayout="fixed"
      />
    </div>
  );
};

export default AITraits;
