import { CopyOutlined } from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import "./index.scss";

const UnifiedProfile = () => {
  const uploadData = [
    {
      key: "1",
      userAgent: "Mozilla/5.0 (Windows NT 10.0)",
      lastSeen: "2024-09-28",
      visitType: "New",
      deviceType: "Desktop",
      numOfVisits: "15",
      email: "john.doe@example.com",
      lastEmail: "support@example.com",
      lastUpdated: "2",
      phoneNumber: "+1-800-555-1234",
      lastPhoneNumber: "+1-800-555-5678",
      location: "New York, USA",
      lastLocation: "Los Angeles, USA",
      ip: "192.168.0.1",
      ipState: "New York",
      ipCity: "New York City",
      ipAddressType: "IPv4",
      source: "Google",
      initialId: "G12345",
      universalId: "U12345",
      updated: "1 day ago",
    },
    {
      key: "2",
      userAgent: "Mozilla/5.0 (Android)",
      lastSeen: "2024-09-30",
      visitType: "Returning",
      deviceType: "Mobile",
      numOfVisits: "20",
      email: "jane.smith@example.com",
      lastEmail: "info@example.com",
      lastUpdated: "5",
      phoneNumber: "+1-800-555-9876",
      lastPhoneNumber: "+1-800-555-6543",
      location: "Chicago, USA",
      lastLocation: "Houston, USA",
      ip: "192.168.0.2",
      ipState: "Illinois",
      ipCity: "Chicago",
      ipAddressType: "IPv6",
      source: "Facebook",
      initialId: "FB54321",
      universalId: "U54321",
      updated: "3 days ago",
    },
    {
      key: "3",
      userAgent: "Mozilla/5.0 (Linux)",
      lastSeen: "2024-09-29",
      visitType: "New",
      deviceType: "Desktop",
      numOfVisits: "10",
      email: "bob.jones@example.com",
      lastEmail: "sales@example.com",
      lastUpdated: "3",
      phoneNumber: "+1-800-555-2222",
      lastPhoneNumber: "+1-800-555-3333",
      location: "San Francisco, USA",
      lastLocation: "Seattle, USA",
      ip: "192.168.0.3",
      ipState: "California",
      ipCity: "San Francisco",
      ipAddressType: "IPv4",
      source: "LinkedIn",
      initialId: "LI67890",
      universalId: "U67890",
      updated: "2 days ago",
    },
    {
      key: "4",
      userAgent: "Mozilla/5.0 (iPad)",
      lastSeen: "2024-09-27",
      visitType: "Returning",
      deviceType: "Tablet",
      numOfVisits: "25",
      email: "alice.davis@example.com",
      lastEmail: "billing@example.com",
      lastUpdated: "1",
      phoneNumber: "+1-800-555-4444",
      lastPhoneNumber: "+1-800-555-5555",
      location: "Miami, USA",
      lastLocation: "Boston, USA",
      ip: "192.168.0.4",
      ipState: "Florida",
      ipCity: "Miami",
      ipAddressType: "IPv6",
      source: "Twitter",
      initialId: "T98765",
      universalId: "U98765",
      updated: "4 days ago",
    },
  ];

  const personColumnsConfig = [
    {
      title: "SOURCE",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "INITIAL ID",
      dataIndex: "initialId",
      key: "initialId",
    },
    {
      title: "UNIVERSAL ID",
      dataIndex: "universalId",
      key: "universalId",
    },
    {
      title: "UPDATED",
      dataIndex: "updated",
      key: "updated",
    },
  ];

  const emailColumnsConfig = [
    {
      title: "EMAILS",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "LAST EMAIL",
      dataIndex: "lastEmail",
      key: "lastEmail",
      width: 200,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "LAST UPDATED",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      width: 120,
      render: (text) => <span>{text} days ago</span>,
    },
  ];

  const phoneColumnsConfig = [
    {
      title: "PHONE NUMBERS",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 200,
    },
    {
      title: "LAST PHONE NUMBER",
      dataIndex: "lastPhoneNumber",
      key: "lastPhoneNumber",
      width: 200,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "LAST UPDATED",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      width: 120,
      render: (text) => <span>{text} days ago</span>,
    },
  ];

  const locationColumnsConfig = [
    {
      title: "LOCATIONS",
      dataIndex: "location",
      key: "location",
      width: 200,
    },
    {
      title: "LAST LOCATION",
      dataIndex: "lastLocation",
      key: "lastLocation",
      width: 200,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "LAST UPDATED",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      width: 120,
      render: (text) => <span>{text} days ago</span>,
    },
  ];

  const ipColumnsConfig = [
    {
      title: "IPs",
      dataIndex: "ip",
      key: "ip",
      width: 200,
    },
    {
      title: "Last Seen",
      dataIndex: "lastSeen",
      key: "lastSeen",
      width: 150,
      render: (text) => <span>{text} days ago</span>,
    },
    {
      title: "IP State",
      dataIndex: "ipState",
      key: "ipState",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "IP City",
      dataIndex: "ipCity",
      key: "ipCity",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "IP Address Type",
      dataIndex: "ipAddressType",
      key: "ipAddressType",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "# of Visits",
      dataIndex: "visits",
      key: "visits",
      width: 120,
      render: (text) => <span>{text}</span>,
    },
  ];

  const agentColumnsConfig = [
    {
      title: "USER AGENTS",
      dataIndex: "userAgent",
      key: "userAgent",
      width: 200,
    },
    {
      title: "LAST SEEN",
      dataIndex: "lastSeen",
      key: "lastSeen",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "VISIT TYPE",
      dataIndex: "visitType",
      key: "visitType",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "DEVICE TYPE",
      dataIndex: "deviceType",
      key: "deviceType",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "# OF VISITS",
      dataIndex: "numOfVisits",
      key: "numOfVisits",
      width: 120,
      render: (text) => <span>{text}</span>,
    },
  ];

  const createUploadColumns = (config) => {
    const columns = config.map((columnConfig) => ({
      title: columnConfig.title,
      dataIndex: columnConfig.dataIndex,
      key: columnConfig.key,
      render:
        columnConfig.render ||
        ((text) => (
          <div className="unified-profile__icon-row">
            <span>{text}</span>
          </div>
        )),
    }));
    return columns;
  };

  const emailsColumns = [
    {
      title: "EMAILS",
      dataIndex: "emails",
      key: "emails",
      render: (emails) => (
        <div className="email-table__emails">
          <div className="email-table__emails-list-title">
            LIST OF ASSOCIATED EMAILS
          </div>
          {emails.map((email, index) => (
            <div key={index} className="email-table__email-item">
              {email}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "LAST EMAIL",
      dataIndex: "lastEmail",
      key: "lastEmail",
      render: (lastEmail) => (
        <div className="email-table__last-email">
          {lastEmail}
          <Tooltip title="Copy email">
            <CopyOutlined className="email-table__copy-icon" />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "UPDATED",
      dataIndex: "updated",
      key: "updated",
      render: (updated) => (
        <div className="email-table__updated">{updated}</div>
      ),
    },
  ];

  const emailsData = [
    {
      key: "1",
      emails: ["and.rew.12@gmail.com", "janvangel@gmail.com"],
      lastEmail: "and.rew.12@gmail.com",
      updated: "15 days ago",
    },
  ];

  const phonesColumns = [
    {
      title: "PHONE NUMBERS",
      dataIndex: "phoneNumbers",
      key: "phoneNumebrs",
      render: (phoneNumbers) => (
        <div className="email-table__emails">
          <div className="email-table__emails-list-title">
            LIST OF ASSOCIATED PHONE NUMBERS
          </div>
          {phoneNumbers.map((phoneNumber, index) => (
            <div key={index} className="email-table__email-item">
              {phoneNumber}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "LAST PHONE NUMBER",
      dataIndex: "lastPhoneNumber",
      key: "lastPhoneNumber",
      render: (lastPhoneNumber) => (
        <div className="email-table__last-email">
          {lastPhoneNumber}
          <Tooltip title="Copy number">
            <CopyOutlined className="email-table__copy-icon" />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "UPDATED",
      dataIndex: "updated",
      key: "updated",
      render: (updated) => (
        <div className="email-table__updated">{updated}</div>
      ),
    },
  ];

  const phoneNumbersData = [
    {
      key: "1",
      phoneNumbers: ["+125-221-4344234", "+421-531-9823173"],
      lastPhoneNumber: "+125-221-43442344",
      updated: "15 days ago",
    },
  ];

  const locationsColumns = [
    {
      title: "LOCATIONS",
      dataIndex: "locations",
      key: "locations",
      render: (locations) => (
        <div className="email-table__emails">
          <div className="email-table__emails-list-title">
            LIST OF ASSOCIATED LOCATIONS
          </div>
          {locations.map((location, index) => (
            <div key={index} className="email-table__email-item">
              {location}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "LAST LOCATION",
      dataIndex: "lastLocation",
      key: "lastLocation",
      render: (lastLocation) => (
        <div className="email-table__last-email">
          {lastLocation}
          <Tooltip title="Copy location">
            <CopyOutlined className="email-table__copy-icon" />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "UPDATED",
      dataIndex: "updated",
      key: "updated",
      render: (updated) => (
        <div className="email-table__updated">{updated}</div>
      ),
    },
  ];

  const locationsData = [
    {
      key: "1",
      locations: ["3413 Gudio St Oakland CA", "3414 Gudio St Oakland CA"],
      lastLocation: "3414 Gudio St Oakland CA",
      updated: "15 days ago",
    },
  ];
  return (
    <div className="unified-profile">
      <Table
        className="unified-profile__table"
        columns={emailsColumns}
        dataSource={emailsData}
        pagination={false}
        tableLayout="fixed"
      />
      <Table
        className="unified-profile__table"
        columns={phonesColumns}
        dataSource={phoneNumbersData}
        pagination={false}
        tableLayout="fixed"
      />
      <Table
        className="unified-profile__table"
        columns={locationsColumns}
        dataSource={locationsData}
        pagination={false}
        tableLayout="fixed"
      />
      <Table
        className="unified-profile__table"
        columns={createUploadColumns(ipColumnsConfig)}
        dataSource={uploadData}
        pagination={false}
        tableLayout="fixed"
      />
      <Table
        className="unified-profile__table"
        columns={createUploadColumns(agentColumnsConfig)}
        dataSource={uploadData}
        pagination={false}
        tableLayout="fixed"
      />
    </div>
  );
};

export default UnifiedProfile;
