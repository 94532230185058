import { SearchOutlined } from "@ant-design/icons";
import { Image, Input, message, Spin } from "antd";
import { IconChevronLeft } from "images";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Flex from "screens/Chat/Flex";
import { getCatalogsList } from "store/modules/connector/action";
import "./index.scss";
import FileUpload from "components/FileUpload";
import InprogressUploads from "components/InprogressUploads";
import { getSelectedWorkspaceProfileId } from "utils";
import { FON_STAGE } from "../Onboarding/Uploads/UnprocessedFiles";
import { createCsvMapping, getFiles } from "api/FileOnboard";
import {
  setCurrentProcessingGPTDetails,
  setNewFileUploaded,
} from "store/modules/fileOnboard/action";

function CatalogList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [filteredNewSources, setFilteredNewSources] = useState([]);
  const catalogsList = useSelector(
    (state) => state?.connectorReducer?.catalogsList
  );
  const isLoader = useSelector((state) => state?.connectorReducer?.loading);
  // const [readyForStep2List, setReadyForStep2List] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [reFetchFileDetails, setReFetchFileDetails] = useState(true);
  const newFileUploaded = useSelector(
    (state) => state.fileOnboardReducer
  ).newFileUploaded;
  const userSession = useSelector((state) => state.authReducer).userSession;

  useEffect(() => {
    dispatch(getCatalogsList());
  }, [dispatch]);

  // console.log("newFileUploaded", newFileUploaded);
  useEffect(() => {
    if (newFileUploaded === null) {
      return;
    }
    // return;
    const payload = {
      user_id: userSession.userId,
      profileworkspaceId: getSelectedWorkspaceProfileId(userSession),
    };
    if (newFileUploaded) {
      setLoadingText("Validating file(s), please wait...");
    }
    setLoading(true);
    const isFileUploadedRecently =
      new Date().getTime() - newFileUploaded < 3000; //Within 3 sec
    setTimeout(
      () => {
        getFiles(payload)
          .then((res) => {
            // console.log("Files A", res);
            const files = res?.data?.data?.response || [];
            const readyForStep2Files = [];

            files.forEach((file) => {
              switch (file.status) {
                case FON_STAGE.UNPROCESSED:
                  readyForStep2Files.push(file);
                  break;
                default:
                  break;
              }
            });

            // const continueButton = {
            //   file_id: 0,
            //   filename: "",
            //   status: FON_STAGE.CONTINUE_BUTTON,
            //   stage: "",
            //   s3_url: "",
            //   error: null,
            //   reason: null,
            //   created: "2024-02-01T09:32:44.278Z",
            //   last_modified: "2024-02-01T09:32:44.278Z",
            // };

            // if (readyForStep2Files.length > 0) {
            //   readyForStep2Files.push(continueButton);
            // }
            console.log("readyForStep2Files", readyForStep2Files);
            // setReadyForStep2List(readyForStep2Files);
            if (readyForStep2Files.length > 0 && newFileUploaded) {
              dispatch(setNewFileUploaded(null));
              setLoadingText("Validating file(s) schema, please wait...");
              console.log("Files B", readyForStep2Files);
              setLoading(true);

              let fileIds = readyForStep2Files
                .filter((fileInfo) => fileInfo.file_id !== 0)
                .map((fileInfo) => fileInfo.file_id);

              const payload = { files: fileIds };

              createCsvMapping(payload)
                .then((res) => {
                  console.log("Files C", res);
                  if (res.data.error) {
                    // message.error(res.data.message);
                  } else {
                    message.success(res.data.message);
                    if (res.data.message) {
                      dispatch(setCurrentProcessingGPTDetails(res?.data?.data));
                      setReFetchFileDetails(!reFetchFileDetails);
                      navigate(`/dashboard/datasets/sources`);
                      // onClickAddSource();
                    }
                  }
                })
                .catch((err) => {
                  message.error(err.data.message);
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          })
          .catch((err) => {
            message.error(err.message);
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      isFileUploadedRecently ? 3000 : 0 //Give time for recent file to be available in db before fetching the list
    );
  }, [newFileUploaded, reFetchFileDetails]);

  const [mainHeading, setMainHeading] = useState({
    MianNewSources: [
      {
        mainid: 1,
        mainHeading: "Most Recent Sources",
        mainDescription1:
          "Explore recent additions to sources catalog by popular demand.",
        mainDescription2: "",
      },
    ],
  });

  const handleSearch = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);

    const filterData = (data, stateSetter) => {
      const filtered = data.filter((item) =>
        item.type.toLowerCase().includes(inputValue)
      );
      stateSetter(filtered);
    };

    filterData(catalogsList, setFilteredNewSources);
  };

  const gotoDetails = (name, id) => {
    const subRoute = name.toLowerCase().split(" ").join("");
    if (id !== 8) {
      navigate(`/dashboard/datasets/sources/${subRoute}/${id}`);
    } else {
      navigate(`/dashboard/datasets/sources/${subRoute}`);
    }
  };
  const handleBackClick = () => {
    // Navigate back to the previous page
    navigate(-1); // Go back one step
  };

  return (
    <>
      <div className="CatalogList">
        {/* <div className="CatalogList__headerText">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span
            className="CatalogList__title"
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={handleBackClick}
            onMouseEnter={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.textDecoration = "none")
            }
          >
            Connections
          </span>
          <span className="CatalogList__slash">/</span>
          <span className="CatalogList__mainTitle">Catalog</span>
        </div>

        <div className="CatalogList__sourceText">
          <span></span>
        </div> */}

        <div className="CatalogList__bar">
          <div className="CatalogList__tagLink">
            {/* <a className="CatalogList__link">Catalog documentation</a> */}
          </div>
          <Input
            className="CatalogList__input"
            type="search"
            onChange={handleSearch}
            value={searchInput}
            placeholder="Search an integration..."
            prefix={<SearchOutlined style={{ color: "grey" }} />}
          />
        </div>

        {/* ------------- New Source Portion ----------------- */}
        <div className="CatalogList__MainDiv">
          {mainHeading.MianNewSources.map((item, index) => {
            return (
              <>
                <div key={item.mainid} className="CatalogList__newSource">
                  <span className="CatalogList__newText">
                    {item.mainHeading}
                  </span>
                </div>
                <div className="CatalogList__exploreBar">
                  <div className="CatalogList__exploreText">
                    {item.mainDescription1}
                  </div>
                  <a className="CatalogList__exploreLink">
                    {item.mainDescription2}
                  </a>
                </div>
              </>
            );
          })}
        </div>
        {isLoader ? (
          <div className="loading-container">
            <Spin />
          </div>
        ) : (
          <div className="CatalogList__allFlexBox">
            {searchInput
              ? filteredNewSources.map((item, index) => {
                  return (
                    <>
                      <div
                        key={item.id}
                        className="CatalogList__singleFlexBox"
                        onClick={() => gotoDetails(item.type, item?.id)}
                      >
                        <div onClick={() => gotoDetails(item.type, item?.id)}>
                          <Image src={item.icon_url} preview={false} />
                        </div>
                        <div className="CatalogList__listText">
                          <div className="CatalogList__listText__text1">
                            {item.type}
                          </div>
                          <div className="CatalogList__listText__text2">
                            {item.source_category}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              : catalogsList
                  ?.sort((a, b) => b.id - a.id)
                  ?.map((item, index) => {
                    // console.log("item", item);
                    return (
                      <>
                        {item.id === 8 && item.type === "CSV" ? (
                          <div>
                            <Spin spinning={loading}>
                              <FileUpload>
                                <div
                                  key={item.id}
                                  className="CatalogList__singleFlexBox__csv"
                                >
                                  <div>
                                    <Image
                                      className="CatalogList__image"
                                      src={item.icon_url}
                                      preview={false}
                                    />
                                  </div>
                                  <div className="CatalogList__listText">
                                    <div className="CatalogList__listText__text1">
                                      {item.type}
                                    </div>
                                    <div className="CatalogList__listText__text2">
                                      {item.source_category}
                                    </div>
                                  </div>
                                </div>
                              </FileUpload>
                            </Spin>

                            <InprogressUploads />
                          </div>
                        ) : (
                          <div
                            key={item.id}
                            className="CatalogList__singleFlexBox highlightedOnHover"
                            onClick={() => gotoDetails(item.type, item?.id)}
                          >
                            <div
                              onClick={() => gotoDetails(item.type, item?.id)}
                            >
                              <Image
                                className="CatalogList__image"
                                src={item.icon_url}
                                preview={false}
                              />
                            </div>
                            <div className="CatalogList__listText">
                              <div className="CatalogList__listText__text1">
                                {item.type}
                              </div>
                              <div className="CatalogList__listText__text2">
                                {item.source_category}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
          </div>
        )}
      </div>
    </>
  );
}

export default CatalogList;
