import React from "react";
import { Tabs } from "antd";
import "./index.scss";
import PlacesSearch from "../PlacesSearch";
import CompaniesSearch from "../CompaniesSearch";
import EventsSearch from "../EventsSearch";
import ProfileSearch from "../ProfileSearch";

const { TabPane } = Tabs;
const Explorer = () => {

  return (
    <Tabs defaultActiveKey="1" className="profile-explorer__tabs">
      <TabPane tab="People" key="1">
       <ProfileSearch/>
      </TabPane>
      <TabPane tab="Places" key="2">
        <PlacesSearch />
      </TabPane>
      <TabPane tab="Companies" key="3">
        <CompaniesSearch />
      </TabPane>
      <TabPane tab="Events" key="4">
        <EventsSearch />
      </TabPane>
    </Tabs>
  );
};

export default Explorer;
